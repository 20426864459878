<template>
  <div class="page-main">
    <div class="page-l" v-if="billId">
      <b-detail-nav :id="billId" />
    </div>
    <div class="page-r">
      <div style="width: 1000px">
        <div class="page-title">基本信息</div>
        <div class="page-form">
          <e-form
            ref="refForm1"
            class="flex-1"
            :config="formConfig"
            :column="formColumn1"
            :data.sync="detailData.report_bill"
          />
          <e-form
            ref="refForm2"
            class="flex-1"
            :config="formConfig"
            :column="formColumn2"
            :data.sync="detailData.report_bill"
          />
        </div>

        <div class="page-title">销售清单</div>
        <!-- 表格 -->
        <vxe-grid
          ref="refTable"
          size="mini"
          border
          highlight-hover-row
          show-footer
          highlight-current-row
          :columns="tableColumn"
          :data="detailData.stock_list"
          :max-height="this.$util.getViewHeight() - 596"
          :keyboard-config="{ isArrow: true }"
          :footer-method="footerMethod"
        >
          <!-- 备注 -->
          <template #default_report_remark="{ row }">
            <el-input v-model="row.report_remark" size="mini" placeholder="请输入备注" clearable />
          </template>
        </vxe-grid>

        <div class="page-title m-t-10">温馨提示</div>
        <e-form
          ref="refForm2"
          class="m-t-10"
          :config="formConfig"
          :column="formColumn3"
          :data.sync="detailData.report_bill"
        />

        <div class="flex flex-x-end">
          <el-button type="primary" size="mini" @click="detailSave">保存</el-button>
          <el-button type="default" size="mini" @click="detailPrint">打印报价单</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isUpdatePage: false,
      isDetailPrint: false,
      detailData: {},
      formConfig: {
        inline: false,
        width: '100%'
      },
      formColumn1: [
        {
          type: 'text',
          title: '报价单号',
          field: 'bill_code',
          disabled: true
        },
        {
          type: 'text',
          title: '供应商',
          field: 'saler_company_name',
          disabled: true
        },
        {
          type: 'text',
          title: '联系人',
          field: 'saler',
          disabled: true
        },
        {
          type: 'number',
          title: '手机号',
          field: 'saler_phone',
          disabled: true
        },
        {
          type: 'text',
          title: '地址',
          field: 'saler_address',
          disabled: true
        }
      ],
      formColumn2: [
        {
          type: 'date',
          title: '报价日期',
          field: 'report_time',
          disabled: true,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          type: 'text',
          title: '采购方',
          field: 'buyer_company_name',
          disabled: true
        },
        {
          type: 'text',
          title: '联系人',
          field: 'buyer',
          disabled: true
        },
        {
          type: 'number',
          title: '手机号',
          field: 'buyer_phone',
          disabled: true
        },
        {
          type: 'text',
          title: '地址',
          field: 'buyer_address',
          disabled: true
        }
      ],
      formColumn3: [
        {
          type: 'radio',
          title: '是否含运',
          field: 'has_freight',
          disabled: true,
          options: [
            {
              key: '否',
              val: false
            },
            {
              key: '是',
              val: true
            }
          ]
        },
        {
          type: 'radio',
          title: '是否含税',
          field: 'has_tax',
          disabled: true,
          options: [
            {
              key: '否',
              val: false
            },
            {
              key: '是',
              val: true
            }
          ]
        },
        {
          type: 'text',
          title: '说明',
          field: 'remark',
          disabled: false,
          width: '100%'
        }
      ],
      tableColumn: [
        {
          title: '项次',
          type: 'seq',
          width: 50
        },
        {
          title: '品名',
          field: 'craft'
        },
        {
          title: '规格(mm)',
          field: 'sales_specification'
        },
        {
          title: '单位',
          field: 'unit'
        },
        {
          title: '单价(元)',
          field: 'sales_price'
        },
        {
          title: '数量',
          field: 'counts'
        },
        {
          title: '金额',
          field: 'sales_amount'
        },
        {
          title: '备注',
          field: 'report_remark',
          width: 300,
          slots: { default: 'default_report_remark' },
          editRender: { autofocus: '.el-input__inner' }
        }
      ]
    }
  },
  computed: {
    billId() {
      return this.$route.query.id
    }
  },
  watch: {
    detailData: {
      handler() {
        this.isUpdatePage = true
      },
      deep: true
    }
  },
  mounted() {
    if (this.billId) {
      this.getDetailData()
    }
  },
  methods: {
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/reportBill/detail',
        params: {
          id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.detailData = this.$util.deepCopy(res.data)

          // 是否含税文案处理
          this.formColumn3.forEach((item) => {
            if (item.field === 'has_tax') {
              if (this.detailData.report_bill.bill_tax_rate) {
                item.options[1].key = `是(含13%增值税发票)`
              }
            }
          })

          setTimeout(() => {
            this.isUpdatePage = false
          }, 100)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 表格底部
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (['counts', 'sales_amount'].includes(column.field)) {
            return this.sumNum(data, column.field)
          }
        })
      ]
    },
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    },
    // 详情-保存
    detailSave() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/reportBill/saveOrUpdate',
        data: this.detailData
      }).then((res) => {
        if (res.data.state === 'ok') {
          if (this.billId) {
            if (this.isDetailPrint) {
              this.isDetailPrint = false
              this.detailPrintConfirm()
              return
            }
            this.$message.success('保存成功')
            this.getDetailData()
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 详情-打印
    detailPrint() {
      if (this.isUpdatePage) {
        this.isDetailPrint = true
        this.detailSave()
      } else {
        this.detailPrintConfirm()
      }
    },
    // 详情-打印-确认
    detailPrintConfirm() {
      window.open(`/sale/quote/print?id=${this.billId}`, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
  position: relative;
  display: flex;
  height: 100%;
  .page-l {
    margin: 0 10px 10px 0;
  }
  .page-r {
    flex: 1;
  }
  .page-title {
    line-height: 40px;
    font-weight: bold;
    font-size: 12px;
    border-bottom: 1px solid @colorGrayD;
    margin-bottom: 10px;
  }
  .page-form {
    display: flex;
    width: 100%;
    margin-top: 10px;
  }
}
</style>
